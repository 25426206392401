body {
  margin: 0;
  padding: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#root {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
li {
  list-style: none;
  margin: 0;
}

a {
  text-decoration: none;
}

.navigation {
  position: absolute;
  margin: 0;
  width: 100%;
  background: rgb(181, 156, 255);
  background: linear-gradient(
    90deg,
    rgb(255, 156, 230) 0%,
    rgb(181, 156, 255) 50%,
    rgb(156, 255, 249) 100%
  );
  text-align: center;
}

.navbar {
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0;
  align-items: right;
  overflow: hidden;
}

.navbar > :nth-child(1) {
  margin-right: auto;
}

.nav-link {
  color: #030303;
}

.content {

  color: rgb(247, 247, 247);
}

.nameHeader {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: auto;
}

.arrows {
	width: 60px;
	height: 72px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: 5vh;
}
@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-2s;
	-webkit-animation-delay:-2s;
}

.arrows path.a2 {
	animation-delay:-1s;
	-webkit-animation-delay:-1s;
}

.arrows path.a3 {	
	animation-delay:0s;
	-webkit-animation-delay:0s;
}
.arrows path {
	stroke: #75f37f;
	fill: transparent;
	stroke-width: 1px;	
	animation: arrow 3s infinite;
	-webkit-animation: arrow 3s infinite; 
}

.spacer {
  aspect-ratio: 1440/100;
  width:100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.spacerOne {
  background-image: url('../imgs/trans1.svg')
}

.textBlock {
  padding-top: 5vh;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 5vh;
}

.textOne {
  background-color: #68bfff;
  color: #030303;
}

.contentBlockOne {
  background: linear-gradient(45deg, rgb(77, 47, 69) 0%, rgb(54, 47, 77) 50%, rgb(47, 77, 75) 100%);
}

.headTwo {
  padding-top: 3vh;
  padding-bottom: 3vh;
}

@media screen and (max-width: 1000px) {
  .textBlock {
    font-size: 3vw;
  }

  .headTwo {
    font-size: 2em;
  }

  .nameHeader {
    height: 94vh;
    padding-left: 5vw;
    font-size: 4vw;
  }
  .navbar {
    padding: 0 1vw 0 1vw;
    width: 98vw;
  }

  .navigation {
    height: 6vh;
    position: fixed;
    bottom: 0;
  }

  .nav-link {
    margin: auto;
    font-size: 4vw;
  }

  .content {
    margin-bottom: 6vh;
    min-height: 94vh;
  }
  .arrows {
    bottom: 10vh;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .textBlock {
    font-size: 2vw;
  }

  .headTwo {
    font-size: 4.5vw;
  }
  
  .nameHeader {
    height: 95vh;
    padding-left: 5vw;
    font-size: 2.2vw;
  }
  .navbar {
    padding: 0 2.5vw 0 2.5vw;
    width: 95vw;
  }

  .navigation {
    height: 6vh;
  }

  .nav-link {
    margin-right: 0.4vw;
    margin-left: 0.4vw;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 2vh;
    overflow: hidden;
  }
  .content {
    padding-bottom: 0;
    padding-top: 6vh;
    min-height: 94vh;
  }
}

@media screen and (min-width: 1500px) {
  .textBlock {
    font-size: 2vw;
  }

  .headTwo {
    font-size: 2em;
  }

  .nameHeader {
    height: 96vh;
    padding-left: 6vw;
    font-size: 2.2vw;
  }
  .navbar {
    padding: 2vh 2vw 0 2vw;
    width: 96vw;
    height:7vh;
  }

  .navigation {
    height: 9vh;
  }

  .nav-link {
    padding-right: 1vw;
    padding-left: 1vw;
    font-size: 4vh;
    height:100%;
  }
  .content {
    padding-top: 9vh;
    padding-bottom: 0;
    min-height: 91vh;
  }
}
